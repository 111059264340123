<template>
  <div class="index">
    <kr-header :token= 'token' />
    <router-view class="contents" />
    <div class="pupop">
      <div class="linksImg" @mouseenter="showIcon(1)" @mouseleave="showIcon(0)">
        <img
          v-if="showIconIndex==1"
          :src="require('@/assets/images/icons/phoneIcon.png')"
          alt
          class="img1"
        />
        <img v-else :src="require('@/assets/images/icons/phoneIcon1.png')" alt class="img1" />
        <div class="hoverBox" style="min-height:30px!important;top:-5px;line-height:30px;">
          <span>客服电话:400-811-0085</span>
        </div>
      </div>
      <div class="linksImg" @mouseenter="showIcon(2)" @mouseleave="showIcon(0)">
        <img
          v-if="showIconIndex==2"
          :src="require('@/assets/images/icons/qqlink.png')"
          alt
          class="img1"
        />
        <img v-else :src="require('@/assets/images/icons/qqlink1.png')" alt class="img1" />
        <div class="hoverBox">
          <p>
            客服01：
            <a target="_blank" href="https://wpa.qq.com/msgrd?v=3&uin=89363072&site=qq&menu=yes" >
              <img border="0"  style="margin-left: 40px;"
                src="https://pub.idqqimg.com/qconn/wpa/button/button_111.gif"
                alt="点击这里给我发消息"
                title="点击这里给我发消息" />
            </a>
          </p>
          <p>
            客服02：
            <a 
              target="_blank"
              href="https://wpa.qq.com/msgrd?v=3&uin=89363872&site=qq&menu=yes"
            >
              <img style="margin-left: 40px;"
                border="0"
                src="https://pub.idqqimg.com/qconn/wpa/button/button_111.gif"
                alt="点击这里给我发消息"
                title="点击这里给我发消息"
              />
            </a>
          </p>

          <p>
            客服03：
            <a
              target="_blank"
              href="https://wpa.qq.com/msgrd?v=3&uin=82306157&site=qq&menu=yes"
            >
              <img style="margin-left: 40px;"
                border="0"
                src="https://pub.idqqimg.com/qconn/wpa/button/button_111.gif"
                alt="点击这里给我发消息"
                title="点击这里给我发消息"
              />
            </a>
          </p>

          <p>
            客服04：
            <a
              target="_blank"
              href="https://wpa.qq.com/msgrd?v=3&uin=76202385&site=qq&menu=yes"
            >
              <img style="margin-left: 40px;"
                border="0"
                src="https://pub.idqqimg.com/qconn/wpa/button/button_111.gif"
                alt="点击这里给我发消息"
                title="点击这里给我发消息"
              />
            </a>
          </p>
        </div>
      </div>
      <div class="linksImg" @mouseenter="showIcon(3)" @mouseleave="showIcon(0)">
        <a href="#" target="_blank" class="tips">
          <img
            v-if="showIconIndex==3"
            :src="require('@/assets/images/icons/weibolink.png')"
            alt
            class="img1"
          />
          <img v-else :src="require('@/assets/images/icons/weibolink1.png')" alt class="img1" />
        </a>
        <div class="hoverBox">
        <img :src="require('@/assets/logo/weibo.png')" class="img" alt />
          扫描关注微博
        </div>
      </div>

      <div class="linksImg" @mouseenter="showIcon(4)" @mouseleave="showIcon(0)">
        <a href="#" target="_blank" class="tips">
          <img
            v-if="showIconIndex==4"
            :src="require('@/assets/images/icons/ioslink.png')"
            alt
            class="img1"
          />
          <img v-else :src="require('@/assets/images/icons/ioslink1.png')" alt class="img1" />
        </a>
        <div class="hoverBox">
       <img :src="require('@/assets/logo/iOS.png')" class="img" alt />
          扫描下载iOS APP
        </div>
      </div>
      <div class="linksImg" @mouseenter="showIcon(5)" @mouseleave="showIcon(0)">
        <a href="#" target="_blank" class="tips">
          <img
            v-if="showIconIndex==5"
            :src="require('@/assets/images/icons/andordlink.png')"
            alt
            class="img1"
          />
          <img v-else :src="require('@/assets/images/icons/andordlink1.png')" alt class="img1" />
        </a>
        <div class="hoverBox" >
        <img :src="require('@/assets/logo/APP.png')" class="img" alt />
          扫描下载Android APP
        </div>
      </div>
      <div class="linksImg" @mouseenter="showIcon(6)" @mouseleave="showIcon(0)">
        <a href="#" target="_blank" class="tips">
          <img
            v-if="showIconIndex==6"
            :src="require('@/assets/images/icons/wxlink.png')"
            alt
            class="img1"
          />
          <img v-else :src="require('@/assets/images/icons/wxlink1.png')" alt class="img1" />
        </a>

        <div class="hoverBox">
        <img :src="require('@/assets/logo/weiChat.jpg')" class="img" alt />
          扫描关注微信公众号
        </div>
      </div>
        <div class="linksImg" @click="backTop()" style="margin-top:50px">
            <!-- <img :src="require('@/assets/images/icons/to-top.png')" alt /> -->
            <i class="el-icon-arrow-up" style="margin:0 auto"></i>
        </div>
    </div>
    <kr-footer style="margin-top:15px"></kr-footer>
  </div>
</template>

<script>
import krHeader from "@/components/kr-header";
import krFooter from '@/components/kr-footer'
import {apiHomeData} from '@/api/public'
export default {
  name: "home",
  components: { krHeader,krFooter },
  data(){
    return{
      token: '',
      showIconIndex: 0
    }
  },
  mounted(){
    this.initData()
    this.$router.push('index')
    this.token = localStorage.getItem('access_token')
  },
  methods: {
    showIcon(index) {
      this.showIconIndex = index;
    },
    initData(){
      apiHomeData().then(res=>{
        console.log(res);
        if(res.code == 200){
          this.$store.commit('SET_INFO',['homeData',res.data])
        }
      })
    },
    backTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  },
};
</script>

<style lang="scss" scoped>
 .index{
  background-color: #f5f6f7;
 }
 .contents{
   min-height: 700px;
 }
 .pupop {
  position: fixed;
  width: 50px;
  height: 300px;
  top: 50%;
  right: 0;
  margin-top: -150px;

  z-index: 999;
  .linksImg {
    height: 38px;
    width: 38px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    line-height: 38px;
    margin-bottom: 4px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    position: relative;
    a {
      // display: block;
      height: 25px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
    .tips {
      font-size: 14px;
    }
    .img1 {
      width: 22px;
      height: 22px;
      margin: 0 auto;
    }
    &:hover {
      .hoverBox {
        display: block;
      }
    }
    .hoverBox {
      position: absolute;
      top: -75px;
      right: 40px;
      width: 160px;
      min-height: 150px;
      border: 1px solid #eee;
      border-radius: 6px;
      background-color: #fff;
      display: none;
      z-index: 5;
      padding: 10px;
      font-size: 14px;
      text-align: center;
      .img {
        display: block;
        width: 130px;
        height: 130px;
        margin: 0 auto;
      }

      p {
        height: 37px;
        display: flex;
        align-items: center;
        line-height: 37px;
        text-align: left;
        text-indent: 5px;
        font-size: 14px;
      }
      span {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        text-align: center;
      }
    }
  }
}
</style>>

