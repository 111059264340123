<template>
  <div class="footer">
    <el-row class="footer_info">
      <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="5">
        <div class="grid-content bg-purple footer_list">
          <p class="footer_list_title">客服中心</p>
          <p class="footer_list_info">
            <img :src="require('@/assets/images/icons/phoneIcon1.png')" alt />
            400-811-0085
          </p>
          <p class="footer_list_info">
            <img :src="require('@/assets/images/icons/emalIcon.png')" alt />
            keroo@mykeroo.com
          </p>
          <p class="footer_list_info">工作日：9:00 - 17:00</p>
          <span class="hidden-sm-and-down"></span>
        </div>
        <div class="line hidden-sm-and-up"></div>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="5">
        <div class="grid-content bg-purple footer_list footer_list_point">
          <p class="footer_list_title">新手帮助</p>
          <p class="footer_list_info" @click="gotoNext('joiningQuestion')">常见问题</p>
          <p class="footer_list_info" @click="gotoNext('joiningNotice')">网站公告</p>
          <p class="footer_list_info" @click="gotoNext('joiningService')">服务协议</p>
          <span class="hidden-sm-and-down"></span>
        </div>
        <div class="line hidden-sm-and-up"></div>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="5">
        <div class="grid-content bg-purple footer_list footer_list_point">
          <p class="footer_list_title">关于我们</p>
          <p class="footer_list_info" @click="gotoNext('joining')">公司介绍</p>
          <p class="footer_list_info" @click="gotoNext('joiningJob')">诚聘英才</p>
          <p class="footer_list_info" @click="gotoNext('joiningContract')">联系我们</p>
          <span class="hidden-sm-and-down"></span>
        </div>
        <div class="line hidden-sm-and-up"></div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="9" :xl="9">
        <el-row class="footer_url">
          <el-col
            :xs="24"
            :sm="8"
            :md="8"
            :lg="8"
            :xl="8"
            class="footer_url_list"
          >
            <img :src="require('@/assets/logo/weiChat.jpg')" alt />
            <p>扫描关注公众号</p>
          </el-col>
          <el-col
            :xs="24"
            :sm="8"
            :md="8"
            :lg="8"
            :xl="8"
            class="footer_url_list"
          >
            <img :src="require('@/assets/logo/iOS.png')" alt />
            <p>扫描下载iOS APP</p>
          </el-col>
          <el-col
            :xs="24"
            :sm="8"
            :md="8"
            :lg="8"
            :xl="8"
            class="footer_url_list"
          >
            <img :src="require('@/assets/logo/APP.png')" alt />
            <p>扫描下载Android APP</p>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="footer_net">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="grid-content bg-purple footer_net_mid">
          keroo系统@ 2018 - 2021
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="grid-content bg-purple footer_net_mid">
          上海可柔科技有限公司
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="grid-content bg-purple footer_net_mid" @click="toGAB('https://beian.miit.gov.cn/')">
          沪ICP备2021016362号-1
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="grid-content bg-purple bg-purples"  @click="toGAB('https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002002839')">
          <img
            :src="require('@/assets/images/gongan.png')"
            style="margin-right: 5px"
            alt
          />
         苏公网安备 32059002002839号
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script lang="ts">
// import qs from "qs";
export default {
  name: 'footers',
  data() {
    return {
      datas: [],
      isBack: false,
      fixedTop: false,
    };
  },
  methods: {
    toGAB(url){
      window.location.href = url
    },
    goBack() {
      this.$router.go(-1);
    },
    gotoNext(val) {
      this.$router.push({name: val})
      this.$store.commit('SET_INFO',['menuIndex','joining'])
    },
    onload() {
      // const data= {};
      // this.axios.post("/wealth/contactUs", qs.stringify(data)).then((res) => {
      //   if (res.data.code === 0) {
      //     this.datas = res.data.data;
      //   } else {
      //     this.$message.warning(res.data.msg);
      //   }
      // });
    },
  },

  mounted() {
    this.onload();
  },
};
</script>
<style lang="scss" scoped>
.line {
  height: 15px;
}
.footer {
  background-color: #fff;
}
.footer_info {
  text-align: center;
  box-sizing: border-box;
  padding-top: 18px;
  border-bottom: 1px solid #666;
  color: #666;
  .footer_list {
    text-align: center;
    position: relative;
    img {
      width: 22px;
      height: 22px;
      margin-right: 12px;
    }
    span {
      position: absolute;
      top: 12px;
      right: 0;
      height: 120px;
      width: 1px;
      background-color: #666;
    }
    &_title {
      line-height: 24px;
      font-size: 18px;
    }
    &_info {
      margin-top: 12px;
      line-height: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
  }
  .footer_list_point {
    .footer_list_info {
      cursor: pointer;
    }
  }
  .footer_url {
    padding-right: 20px;
    padding-top: 9px;
    &_list {
      text-align: center;
      img {
        width: 100px;
      }
      p {
        line-height: 20px;
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 2px;
      }
    }
  }
}
.bg-purples {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_net {
  padding: 0 36px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  .grid-content {
    cursor: pointer;
    a {
      color: #666;
    }
  }
  &_mid {
    height: 18px;
    line-height: 18px;
    margin: 11px auto;
    border-right: 2px solid #666;
  }
}
</style>