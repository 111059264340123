<template>
  <div class="header" id="header">
    <div class="top">
      <el-row>
        <el-col :xs="24" :sm="24" :md="6" :lg="4" :xl="4" class="phone">
          <img :src="require('@/assets/images/icons/phoneIcon1.png')" alt />
          <span>客服电话:400-811-0085</span>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="12" :xl="12" class="links">
          <span class="span1"></span>
          <div
            class="linksImg"
            @mouseenter="showIcon(1)"
            @mouseleave="showIcon(0)"
          >
            <img
              v-if="showIconIndex == 1"
              :src="require('@/assets/images/icons/qqlink.png')"
              alt
              class="img1"
            />
            <img
              v-else
              :src="require('@/assets/images/icons/qqlink1.png')"
              alt
              class="img1"
            />
            <div class="hoverBox">
              <p>
                客服01：
                <a
                  target="_blank"
                  href="http://wpa.qq.com/msgrd?v=3&uin=89363072&site=qq&menu=yes"
                >
                  <img
                    border="0"
                    src="https://pub.idqqimg.com/qconn/wpa/button/button_111.gif"
                    alt="点击这里给我发消息"
                    title="点击这里给我发消息"
                  />
                </a>
              </p>
              <p>
                客服02：
                <a
                  target="_blank"
                  href="http://wpa.qq.com/msgrd?v=3&uin=89363872&site=qq&menu=yes"
                >
                  <img
                    border="0"
                    src="https://pub.idqqimg.com/qconn/wpa/button/button_111.gif"
                    alt="点击这里给我发消息"
                    title="点击这里给我发消息"
                  />
                </a>
              </p>

              <p>
                客服03：
                <a
                  target="_blank"
                  href="http://wpa.qq.com/msgrd?v=3&uin=82306157&site=qq&menu=yes"
                >
                  <img
                    border="0"
                    src="https://pub.idqqimg.com/qconn/wpa/button/button_111.gif"
                    alt="点击这里给我发消息"
                    title="点击这里给我发消息"
                  />
                </a>
              </p>

              <p>
                客服04：
                <a
                  target="_blank"
                  href="http://wpa.qq.com/msgrd?v=3&uin=76202385&site=qq&menu=yes"
                >
                  <img
                    border="0"
                    src="https://pub.idqqimg.com/qconn/wpa/button/button_111.gif"
                    alt="点击这里给我发消息"
                    title="点击这里给我发消息"
                  />
                </a>
              </p>
            </div>
          </div>
          <div
            class="linksImg"
            @mouseenter="showIcon(2)"
            @mouseleave="showIcon(0)"
          >
            <a href="#" target="_blank" class="tips">
              <img
                v-if="showIconIndex == 2"
                :src="require('@/assets/images/icons/weibolink.png')"
                alt
                class="img1"
              />
              <img
                v-else
                :src="require('@/assets/images/icons/weibolink1.png')"
                alt
                class="img1"
              />
            </a>
            <div class="hoverBox">
              <img :src="require('@/assets/logo/weibo.png')" class="img" alt />
              <span class="span">扫描关注微博</span>
            </div>
          </div>

          <div
            class="linksImg"
            @mouseenter="showIcon(3)"
            @mouseleave="showIcon(0)"
          >
            <a href="#" target="_blank" class="tips">
              <img
                v-if="showIconIndex == 3"
                :src="require('@/assets/images/icons/ioslink.png')"
                alt
                class="img1"
              />
              <img
                v-else
                :src="require('@/assets/images/icons/ioslink1.png')"
                alt
                class="img1"
              />
            </a>
            <div class="hoverBox">
              <img :src="require('@/assets/logo/iOS.png')" class="img" alt />
              <span class="span">扫描下载iOS APP</span>
            </div>
          </div>
          <div
            class="linksImg"
            @mouseenter="showIcon(4)"
            @mouseleave="showIcon(0)"
          >
            <a href="#" target="_blank" class="tips">
              <img
                v-if="showIconIndex == 4"
                :src="require('@/assets/images/icons/andordlink.png')"
                alt
                class="img1"
              />
              <img
                v-else
                :src="require('@/assets/images/icons/andordlink1.png')"
                alt
                class="img1"
              />
            </a>
            <div class="hoverBox">
              <img :src="require('@/assets/logo/APP.png')" class="img" alt />

              <span class="span">扫描下载Android APP</span>
            </div>
          </div>
          <div
            class="linksImg"
            @mouseenter="showIcon(5)"
            @mouseleave="showIcon(0)"
          >
            <a href="#" target="_blank" class="tips">
              <img
                v-if="showIconIndex == 5"
                :src="require('@/assets/images/icons/wxlink.png')"
                alt
                class="img1"
              />
              <img
                v-else
                :src="require('@/assets/images/icons/wxlink1.png')"
                alt
                class="img1"
              />
            </a>
            <div class="hoverBox">
              <img
                :src="require('@/assets/logo/weiChat.jpg')"
                class="img"
                alt
              />
              <span class="span">扫描关注微信公众号</span>
            </div>
          </div>
          <!-- <span></span>
          <p @click="toNewHelp" style=" cursor: pointer;">新手帮助</p> -->
        </el-col>
        <el-col
          :xs="24"
          :sm="12"
          :md="10"
          :lg="8"
          :xl="8"
          class="wellcome"
          v-if="$store.state.token"
        >
          <el-badge class="item"
            ><p>
              <span v-if="$store.state.token"
                >欢迎您 {{ userInfo.nickname }}</span
              >
            </p></el-badge
          >
          <!-- <el-badge :value="3" class="item"><p>消息中心</p></el-badge> -->
          <el-badge
            class="item"
            @click.native="handleCommandAccount('accountOrder')"
            ><p>我的订单</p></el-badge
          >
          <el-badge
            @click.native="handleCommandAccount('accountCart')"
            class="item"
            ><p>
              <i
                class="el-icon-shopping-cart-full"
                style="font-size: 20px; color: orange"
              ></i
              >我的购物车
            </p></el-badge
          >
          <p @click="logout" style="cursor: pointer; margin-left: 10px">
            退出登录
          </p>
        </el-col>
        <el-col
          :xs="24"
          :sm="12"
          :md="10"
          :lg="8"
          :xl="8"
          class="wellcome"
          v-else
        >
          欢迎您来到keroo大家庭
        </el-col>
      </el-row>
    </div>
    <el-row class="container">
      <div class="containerBox">
        <img :src="require('@/assets/logo/headerlog.png')" alt class="logo" />
        <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
          <div class="menus">
            <div class="menus_list" v-if="$store.state.token">
              <el-dropdown @command="handleCommandAccount">
                <span
                  :class="
                    $store.state.menuIndex === 'account'
                      ? 'el-dropdown-link actived'
                      : 'el-dropdown-link'
                  "
                >
                  我的账户
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="account"
                    >我的资料</el-dropdown-item
                  >
                  <el-dropdown-item command="accountTruthName"
                    >实名认证</el-dropdown-item
                  >
                  <!-- <el-dropdown-item command="accountNews">消息中心</el-dropdown-item> -->
                  <el-dropdown-item command="accountAddress"
                    >收货地址</el-dropdown-item
                  >
                  <el-dropdown-item command="accountOrder"
                    >我的订单</el-dropdown-item
                  >
                  <el-dropdown-item command="accountCart"
                    >我的购物车</el-dropdown-item
                  >
                  <el-dropdown-item command="accountTuijian"
                    >我的推荐</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="menus_list" v-if="!$store.state.token">
              <div class="logBox">
                <span class="span" @click="toRegister">注册</span>
                <span class="s1"></span>
                <span class="span" @click="toLogin">登录</span>
              </div>
            </div>
            <div class="menus_list">
              <span class="line"></span>
            </div>
            <div
              :class="
                $store.state.menuIndex === 'joining'
                  ? 'menus_list actived'
                  : 'menus_list'
              "
            >
              <el-dropdown @command="handleCommandAS">
                <span
                  :class="
                    $store.state.menuIndex === 'joining'
                      ? 'el-dropdown-link actived'
                      : 'el-dropdown-link'
                  "
                >
                  关于我们
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="joining"
                    >加入我们</el-dropdown-item
                  >
                  <el-dropdown-item command="joiningNotice"
                    >网站公告</el-dropdown-item
                  >
                  <el-dropdown-item command="joiningService"
                    >服务协议</el-dropdown-item
                  >
                  <el-dropdown-item command="joiningQuestion"
                    >常见问题</el-dropdown-item
                  >
                  <el-dropdown-item command="joiningJob"
                    >诚聘英才</el-dropdown-item
                  >
                  <el-dropdown-item command="joiningContract"
                    >联系我们</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div
              :class="
                $store.state.menuIndex === 'mall'
                  ? 'menus_list actived'
                  : 'menus_list'
              "
              @click="toPages('mall')"
            >
              品牌商城
            </div>
            <div
              :class="
                $store.state.menuIndex === 'actions'
                  ? 'menus_list actived'
                  : 'menus_list'
              "
              @click="toPages('actions')"
            >
              品牌动态
            </div>
            <div
              :class="
                $store.state.menuIndex === 'brand'
                  ? 'menus_list actived'
                  : 'menus_list'
              "
              @click="toPages('brand')"
            >
              品牌中心
            </div>
            <div
              :class="
                $store.state.menuIndex === 'index'
                  ? 'menus_list actived'
                  : 'menus_list'
              "
              @click="toPages('index')"
            >
              首页
            </div>
          </div>
        </el-col>
      </div>
    </el-row>
  </div>
</template>
<script>
import { apiGetCartCount } from "@/api/goods";
export default {
  name: "krheader",
  data() {
    return {
      menuIndex: this.$store.state.menuIndex,
      datas: {},
      showIconIndex: 0,
      userInfo: {},
    };
  },
  mounted() {
    this.userInfo = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : {};
    if (this.$store.state.token) {
      this.getCartCount();
    }
  },
  methods: {
    //获取购物车数量
    getCartCount() {
      apiGetCartCount({ numType: true, type: "sum" }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$store.commit("SET_INFO", ["cartCount", res.data.count]);
        } else {
          this.$store.commit("SET_INFO", ["cartCount", 0]);
        }
      });
    },
    toPages(item) {
      this.$router.push({ name: item });
      this.$store.commit("SET_INFO", ["menuIndex", item]);
    },
    showIcon(index) {
      this.showIconIndex = index;
    },
    toNewHelp() {},
    toAdmin() {},
    handleCommandAS(val) {
      this.$router.push({ name: val });
      this.$store.commit("SET_INFO", ["menuIndex", "joining"]);
    },
    handleCommandAccount(val) {
      console.log(val);
      localStorage.setItem("accountIndex", val);
      this.$router.push({ name: val });
      this.$store.commit("SET_INFO", ["menuIndex", val]);
    },
    toEmail() {},
    toRegister() {
      this.$router.push("register");
    },
    toLogin() {
      this.$router.push("login");
    },
    logout() {
      this.$confirm("确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.clear();
          this.$store.commit("SET_INFO", ["token", ""]);
          this.$store.commit("SET_INFO", ["userInfo", {}]);
          this.$router.replace("/index");
        })
        .catch(() => {});
    },
    getUserInfo() {},
  },
};
</script>
<style lang="scss" scoped>
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #ecf5ff;
  color: blue;
}
.el-dropdown {
  font-size: 16px;
}
.header {
  background-color: #fff;
  .top {
    line-height: 38px;
    background-color: #fff;
    border-bottom: 1px solid #f00;
    padding: 0 30px;
    font-size: 12px;
    img {
      width: 22px;
      height: 22px;
    }
    .phone {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 15px;
      img {
        margin-right: 8px;
      }
    }
    .links {
      text-align: left;
      display: flex;
      align-items: center;
      height: 38px;
      .linksImg {
        height: 38px;
        line-height: 38px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        position: relative;
        .tips {
          color: #333;
          width: 22px;
          height: 22px;
          line-height: 22px;
          font-size: 14px;
          text-align: center;
          display: block;
        }
        .img1 {
          width: 22px;
          height: 22px;
          display: inline-block;
        }
        &:hover {
          .hoverBox {
            display: block;
          }
        }
        .hoverBox {
          position: absolute;
          top: 35px;
          width: 150px;
          min-height: 150px;
          //    background-color: red;
          border: 1px solid #eee;
          border-radius: 6px;
          background-color: #fff;
          display: none;
          z-index: 5999;
          // padding: 10px 10px 5px;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
          a {
            display: block;
            height: 25px;
          }
          .span {
            width: 150px;
            display: inline-block;
            text-align: center;
            line-height: 16px;
            background-color: #fff;
            font-size: 12px;
          }
          p {
            height: 26px;
            display: flex;
            align-items: center;
            line-height: 26px;
            text-align: left;
            text-indent: 5px;
            font-size: 12px;
          }
        }
      }
    }
    .wellcome {
      height: 38px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: center;
      .el-badge {
        margin-right: 25px;
      }
      p {
        line-height: 26px;
      }
    }
  }
  .container {
    box-sizing: border-box;
    background-color: #fff;
    .containerBox {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .logo {
      width: 200px;
      height: 75px;
    }
    .menus {
      flex-direction: row-reverse;
      text-align: right;
      height: 80px;
      display: flex;
      align-items: center;
    }
    .menus_list {
      &:hover {
        color: blue;
      }
      padding-right: 50px;
      font-size: 16px;
      height: 80px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .line {
        height: 24px;
        width: 2px;
        background-color: #666;
      }
      .logBox {
        height: 38px;
        line-height: 38px;
        border-radius: 19px;
        border: 1px solid #7f7f7f;
        width: 120px;
        .s1 {
          border-right: 1px solid #7f7f7f;
          display: inline-block;
          width: 1px;
          height: 16px;
        }
        .span {
          display: inline-block;
          width: 58px;
          line-height: 38px;
          height: 38px;
          font-size: 16px;
          text-align: center;
        }
        // span:first-child{
        //     border-right: 1px solid #7f7f7f;
        // }
      }
    }
    // .menus_list:hover{
    //     color: blue;
    // }
  }
}
.actived {
  color: blue;
}
::v-deep.el-badge__content.is-fixed {
  position: absolute;
  top: 6px;
  right: 0px;
}
</style>